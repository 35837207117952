import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function RemoteGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        Access your camera over the internet - dynamic DNS and Point2Point connection setup.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Internet_Access/Point_to_Point/"><EuiIcon type="grabHorizontal" /> P2P Point-2-Point  </Link>
          <Link to="/Internet_Access/The_DDNS_Service/"><EuiIcon type="grabHorizontal" /> INSTAR DDNS  </Link>
          <Link to="/Internet_Access/Port_Forwarding/"><EuiIcon type="grabHorizontal" /> Port Forwarding  </Link>
          <Link to="/Internet_Access/Mobile_Access/"><EuiIcon type="grabHorizontal" /> Mobile Access  </Link>
          <Link to="/Internet_Access/DDNS_Provider/"><EuiIcon type="grabHorizontal" /> DDNS Provider <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
