import React from 'react'

import {
  EuiTitle,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiHideFor
} from '@elastic/eui'

import SmarthomeCards from './Smarthome/IntroSmarthomeArticles'
import SmarthomeCardsMobile from './Smarthome/IntroSmarthomeArticles_mobile'

export default function IntroHome() {

  return (
    <React.Fragment>
      <EuiSpacer />
        <EuiTitle size="s">
            <h3>Smarthome Articles</h3>
        </EuiTitle>
        <EuiHorizontalRule margin="xs" />
      <EuiFlexGroup gutterSize="l" direction="column" style={{margin: '0 7px'}} >
        <EuiFlexItem>
          <EuiHideFor sizes={['xs', 's', 'm', 'l']}>
            <SmarthomeCards />
          </EuiHideFor>
          <EuiHideFor sizes={['xl']}>
            <SmarthomeCardsMobile />
          </EuiHideFor>
        </EuiFlexItem>
    </EuiFlexGroup>
  </React.Fragment>
  );
}
