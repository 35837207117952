import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function ProductsGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        All INSTAR Products - documentation and software downloads.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Downloads/"><EuiIcon type="grabHorizontal" /> Downloads  </Link>
          <Link to="/Products/Usermanuals/"><EuiIcon type="grabHorizontal" /> Usermanuals  </Link>
          <Link to="/Assistants/Review_Wall/"><EuiIcon type="grabHorizontal" /> Testimonies  </Link>
          <Link to="/Indoor_Cameras/"><EuiIcon type="grabHorizontal" /> Indoor Cameras  </Link>
          <Link to="/Outdoor_Cameras/"><EuiIcon type="grabHorizontal" /> Outdoor Cameras  </Link>
          <Link to="/Products/Lenses/"><EuiIcon type="grabHorizontal" /> Lenses  </Link>
          <Link to="/Web_User_Interface/1080p_Series/Overview/"><EuiIcon type="grabHorizontal" /> Web User Interface  </Link>
          <Link to="/Products/USB-Webcams/IN-W1/"><EuiIcon type="grabHorizontal" /> USB Webcams  </Link>
          <Link to="/Products/IN-LAN/"><EuiIcon type="grabHorizontal" /> IN-LAN  </Link>
          <Link to="/Products/Power_over_Ethernet/"><EuiIcon type="grabHorizontal" /> Power over Ethernet  </Link>
          <Link to="/Products/IN-Route/"><EuiIcon type="grabHorizontal" /> IN-Route <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
