import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function DeveloperGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        Control your camera through it&apos;s HTTP (CGI) or MQTT API and integrate it into your smarthome environment.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Advanced_User/VPN_AVM_Fritzbox/"><EuiIcon type="grabHorizontal" /> VPN and AVM Fritzbox </Link>
          <Link to="/Advanced_User/Website_Integration/"><EuiIcon type="grabHorizontal" /> Website Integration  </Link>
          <Link to="/Advanced_User/Youtube_Videostreaming_from_your_Camera/"><EuiIcon type="grabHorizontal" /> Youtube Videostreaming  </Link>
          <Link to="/Advanced_User/CGI_Commands/"><EuiIcon type="grabHorizontal" /> CGI Commands  </Link>
          <Link to="/Advanced_User/INSTAR_MQTT_Broker/"><EuiIcon type="grabHorizontal" /> INSTAR MQTT Broker  </Link>
          <Link to="/Advanced_User/Homebridge_INSTAR_MQTT_to_Homekit/"><EuiIcon type="grabHorizontal" /> Homebridge INSTAR MQTT  </Link>
          <Link to="/Advanced_User/FHEM_on_a_Raspberry_Pi/"><EuiIcon type="grabHorizontal" /> FHEM on a Raspberry Pi  </Link>
          <Link to="/Advanced_User/Node-RED_and_MQTT/"><EuiIcon type="grabHorizontal" /> Node-RED and MQTT  </Link>
          <Link to="/Advanced_User/Node-RED_Dashboard_Live_Video/"><EuiIcon type="grabHorizontal" /> Node-RED Dashboard Live Video  </Link>
          <Link to="/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"><EuiIcon type="grabHorizontal" /> Alarmserver Queries  </Link>
          <Link to="/Advanced_User/Node-RED_and_SQL-Logging/"><EuiIcon type="grabHorizontal" /> Node-RED and SQL  </Link>
          <Link to="/Advanced_User/Node-RED_on_Android/"><EuiIcon type="grabHorizontal" /> Node-RED on Android  </Link>
          <Link to="/Advanced_User/OpenHAB_Home_Automation/"><EuiIcon type="grabHorizontal" /> OpenHAB  </Link>
          <Link to="/Advanced_User/OpenHAB_IP_Camera_Binding/"><EuiIcon type="grabHorizontal" /> OpenHAB IP Camera Binding  </Link>
          <Link to="/Advanced_User/openHABian_on_Raspberry_Pi/"><EuiIcon type="grabHorizontal" /> openHABian  </Link>
          <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/"><EuiIcon type="grabHorizontal" /> IOBroker  </Link>
          <Link to="/Advanced_User/Homematic_CCU3_and_RedMatic/"><EuiIcon type="grabHorizontal" /> Homematic CCU3 and RedMatic  </Link>
          <Link to="/Advanced_User/Alexa_Voice_Control_without_Cloud/"><EuiIcon type="grabHorizontal" /> Alexa and Node-RED  </Link>
          <Link to="/Advanced_User/IFTTT_and_INSTAR_FHD_Cameras/"><EuiIcon type="grabHorizontal" /> IFTTT and INSTAR FHD Cameras  </Link>
          <Link to="/Advanced_User/IFTTT_as_Alarmserver/"><EuiIcon type="grabHorizontal" /> IFTTT as Alarmserver  </Link>
          <Link to="/Advanced_User/Node-RED_and_IFTTT/"><EuiIcon type="grabHorizontal" /> Node-RED and IFTTT  </Link>
          <Link to="/Advanced_User/XiaoMi_Home_Zigbee2MQTT/"><EuiIcon type="grabHorizontal" /> XiaoMi Home and Zigbee2MQTT  </Link>
          <Link to="/Advanced_User/ZoneMinder_inside_a_Docker_Container/"><EuiIcon type="grabHorizontal" /> ZoneMinder and Docker  </Link>
          <Link to="/Advanced_User/Tasker/"><EuiIcon type="grabHorizontal" /> Tasker on Android <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
