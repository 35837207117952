import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


import openHabSearchIcon from '../../../../images/Search/AU_SearchThumb_OpenHAB.webp'
import blueIrisSearchIcon from '../../../../images/Search/P_SearchThumb_Blue_Iris.webp'
import nodeRedSearchIcon from '../../../../images/Search/AU_SearchThumb_Node-RED.webp'
import faqSearchIcon from '../../../../images/Search/FAQ_SearchThumb.webp'
import lupusSearchIcon from '../../../../images/Search/P_SearchThumb_Lupus_XT2.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB and INSTAR IP Cameras"
                  />
                </div>
              }
              title="OpenHAB 3 Camera Widget"
              description="Create your Camera Create the Widget Template Create your Camera First we need to add our camera as an Equipment. To do this go to Settings and click on Model: Click on Create Equipment from Thing:…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/OpenHAB_3_Camera_Widget/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris v5 and INSTAR IP Cameras"
                  />
                </div>
              }
              title="BlueIris v5 as HTTP Alarmserver"
              description="Q: I don't see any ONVIF trigger events from the camera to the BlueIris Server. The triggers are fired on the camera, images are saved to the SD card, but the server only gets the video/audio stream …"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Frequently_Asked_Question/BlueIris_v5_http_alarmserver/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB and INSTAR IP Cameras"
                  />
                </div>
              }
              title="OpenHAB 3 Home Automation in Docker"
              description="Pulling the Docker Image Preparing the System Running the Container Automating Docker setup using Ansible Maintenance Clean Up when things go wrong OpenHAB CLI Pulling the Docker Image The OpenHAB 3 …"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/OpenHAB_3_in_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB and INSTAR IP Cameras"
                  />
                </div>
              }
              title="OpenHAB 3 INSTAR Camera Binding"
              description="Add a Camera Thing Installing FFMPEG Add an Snapshot Channel Camera Control Add a Camera Thing After installing the camera binding we can continue with adding our camera. Go to Things and select the …"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/OpenHAB_3_Camera_Binding/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB 3 MQTT Binding"
                  />
                </div>
              }
              title="OpenHAB 3 MQTT Binding"
              description="Configuring the MQTT Binding MQTT Broker MQTT Client Configuring the MQTT Binding MQTT Broker I will start by adding my INSTAR IP camera as MQTT Broker to OpenHAB. The broker is configured as follows…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/OpenHAB_3_MQTT_Binding/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris v5 Configure Preset Positions"
                  />
                </div>
              }
              title="BlueIris v5 Configure Preset Positions"
              description="Q: Hello, I have integrated the camera (firmware 4.2.2.20) via Onvif into Blue Iris (5.3.3.8) and would like to use the preset positions. The PTZ control via Blue Iris works completely and with all o…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Frequently_Asked_Question/BlueIris_v5_preset_positions/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={nodeRedSearchIcon}
                    alt="Run a INSTAR Node-RED Dashboard in Docker"
                  />
                </div>
              }
              title="Run a INSTAR Node-RED Dashboard in Docker"
              description="Install Docker on Ubuntu 20.04 Download Node-RED Configuration Run Node-RED in Docker Configuring your Dashboard Used Node-RED Nodes: Docker is an application that simplifies the process of managing…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/Node-RED_in_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={nodeRedSearchIcon}
                    alt="Node-RED Event Timeline for your MQTT Camera"
                  />
                </div>
              }
              title="Node-RED Event Timeline for your MQTT Camera"
              description="Use Case Used Nodes Node-RED Flow Use Case You are sitting in front of screen having to keep an eye on what is happening somewhere with your MQTT camera. You want to be able to see the live video fro…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/Node-RED_Alarm_Event_Timeline/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris MQTT with Home Assistant"
                  />
                </div>
              }
              title="BlueIris MQTT with Home Assistant"
              description="MQTT Alarmserver Controlling BlueIris with MQTT Adding the Live Video MQTT Alarmserver We already configured our cameras in BlueIris to send an MQTT update once the software triggers an alarm. In my …"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/Blue_Iris_v5/Home_Assistant/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris MQTT with Node-RED"
                  />
                </div>
              }
              title="BlueIris MQTT with Node-RED"
              description="MQTT Alarmserver Controlling BlueIris with MQTT JSON Flow Export Adding the Live Video to your Node-RED Dashboard MQTT Alarmserver We already configured our cameras in BlueIris to send an MQTT update…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/Blue_Iris_v5/Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris MQTT with ioBroker"
                  />
                </div>
              }
              title="BlueIris MQTT with ioBroker"
              description="MQTT Alarmserver Controlling BlueIris with MQTT Blockly Script Export MQTT Alarmserver We already set up BlueIris to update an MQTT Topic when an alarm is triggered by our camera (shortname ). This M…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/Blue_Iris_v5/ioBroker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris and INSTAR MQTT"
                  />
                </div>
              }
              title="BlueIris and INSTAR MQTT"
              description="Configuring the BlueIris MQTT Service MQTT Alarm Service Controlling BlueIris through MQTT Admin Commands Configuring the BlueIris MQTT Service To activate the MQTT Service enter the BlueIris Softwar…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={faqSearchIcon}
                    alt="Create a camera overview page in ioBroker Vis via Node-RED"
                  />
                </div>
              }
              title="Create a camera overview page in ioBroker Vis via Node-RED"
              description="Introduction to Node-RED in ioBroker Receive Updates from ioBroker in Node-RED Updating ioBroker Objects through Node-RED UI Create Datapoints Node-RED Script: Alarm Areas Node-RED Script: Alarmserve…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Frequently_Asked_Question/ioBroker_INSTAR_Camera_Vis_with_Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={faqSearchIcon}
                    alt="Homebridge in Node-RED for Apple Homekit"
                  />
                </div>
              }
              title="Homebridge in Node-RED for Apple Homekit"
              description="Q: The Homebridge Tutorial showed how to add buttons and switches to Homebridge so that I am now able to to control my camera through Homebridge in my Homekit App on my iPhone. Is it possible to comb…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Frequently_Asked_Question/Homebridge_in_Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={lupusSearchIcon}
                    alt="Lupus XT2 Plus"
                  />
                </div>
              }
              title="Lupus XT2 Plus"
              description="Adding your Cameras Live Video Controlling the Camera Adding your Cameras Live Video Open in the LUPUS XT the menu Smarthome / Cameras: And load the Camera Settings for the desired camera: Camera…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Other_Platforms/Lupus_XT2_Plus/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
