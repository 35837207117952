import React from 'react'

import {
  EuiTitle,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiHideFor
} from '@elastic/eui'

import LatestTutorials from './Latest/IntroLatestTutorials'
import LatestFAQs from './Latest/IntroLatestFAQs'

export default function IntroHome() {

  return (
    <React.Fragment>
      <EuiSpacer />
      <EuiHideFor sizes={['m', 'l', 'xl']}>
          <EuiTitle size="s">
              <h3>Aktuelle FAQs</h3>
          </EuiTitle>
          <EuiHorizontalRule margin="l" />
          <LatestFAQs />
          <EuiTitle size="s">
              <h3>Aktuelle Artikel</h3>
          </EuiTitle>
          <EuiHorizontalRule margin="l" />
          <LatestTutorials />
      </EuiHideFor>
      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiTitle size="s">
                    <h3>Latest Article</h3>
                </EuiTitle>
                <EuiHorizontalRule margin="l" />
                <EuiFlexGroup>
                  <LatestTutorials />
                </EuiFlexGroup>
            </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle size="s">
                <h3>Latest FAQs</h3>
            </EuiTitle>
            <EuiHorizontalRule margin="l" />
            <EuiFlexGroup>
              <LatestFAQs />
            </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiHideFor>
  </React.Fragment>
  );
}
