import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function InstallationGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        Everything you need to know to get started with your INSTAR IP camera - network connection and basic camera configuration.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Quick_Installation/How_does_an_IP_Camera_Work/"><EuiIcon type="grabHorizontal" /> How does an IP Camera Work?  </Link>
          <Link to="/Quick_Installation/After_Unpacking/"><EuiIcon type="grabHorizontal" /> After Unpacking  </Link>
          <Link to="/Quick_Installation/First_Steps/"><EuiIcon type="grabHorizontal" /> First Steps  </Link>
          <Link to="/Quick_Installation/Power_over_Ethernet/"><EuiIcon type="grabHorizontal" /> Power over Ethernet  </Link>
          <Link to="/Quick_Installation/Powerline/"><EuiIcon type="grabHorizontal" /> Powerline  </Link>
          <Link to="/Quick_Installation/Direct_LAN_Connection/"><EuiIcon type="grabHorizontal" /> Direct LAN Connection  </Link>
          <Link to="/Quick_Installation/Alternative_IP_Scanner/"><EuiIcon type="grabHorizontal" /> Find your Camera  </Link>
          <Link to="/Quick_Installation/Language_Selection/"><EuiIcon type="grabHorizontal" /> Language Selection  </Link>
          <Link to="/Quick_Installation/Live_Video/"><EuiIcon type="grabHorizontal" /> Live Video  </Link>
          <Link to="/Quick_Installation/Creating_User_Accounts/"><EuiIcon type="grabHorizontal" /> User Management  </Link>
          <Link to="/Quick_Installation/WebUI_And_Firmware_Upgrade/"><EuiIcon type="grabHorizontal" /> Software Update  </Link>
          <Link to="/Quick_Installation/How_To_Clear_Your_Browsing_History/"><EuiIcon type="grabHorizontal" /> Browsing History  </Link>
          <Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/"><EuiIcon type="grabHorizontal" /> Wireless Connection  </Link>
          <Link to="/Quick_Installation/ONVIF/"><EuiIcon type="grabHorizontal" /> ONVIF <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
