import React from 'react'
import { Link } from 'gatsby'

import {
  EuiTitle,
  EuiSpacer,
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiHideFor
} from '@elastic/eui'

import InstallationGrid from './Home/IntroInstallationGrid'
import MotionGrid from './Home/IntroMotionGrid'
import RemoteGrid from './Home/IntroRemoteGrid'
import DeveloperGrid from './Home/IntroDeveloperGrid'
import ProductsGrid from './Home/IntroProductsGrid'
import SoftwareGrid from './Home/IntroSoftwareGrid'
import PopularGrid from './Home/IntroPopularGrid'
import LatestArticles from './Home/IntroLatestArticles'


const PANEL_STYLE = { boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)' }

export default function IntroHome() {

  return (
    <React.Fragment>
      <EuiSpacer />

      <EuiHideFor sizes={['xs', 's']}>
        <EuiFlexGroup gutterSize="l" direction="row">
          <EuiFlexItem>
            <EuiFlexGroup gutterSize="l" direction="column">
              <EuiFlexItem>
                <Link to="/Quick_Installation/">
                  <EuiTitle size="s">
                    <h3>Quick Installation</h3>
                  </EuiTitle>
                </Link>
                <EuiHorizontalRule margin="l" />
                <EuiPanel hasBorder={false} hasShadow={false} style={PANEL_STYLE}>
                  <InstallationGrid />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem>
                <Link to="/Motion_Detection/">
                  <EuiTitle size="s">
                    <h3>Motion Detection</h3>
                  </EuiTitle>
                </Link>
                <EuiHorizontalRule margin="xs" />
                <EuiPanel hasBorder={false} hasShadow={false} style={PANEL_STYLE}>
                  <MotionGrid />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem>
                <Link to="/Internet_Access/">
                  <EuiTitle size="s">
                    <h3>Remote Access</h3>
                  </EuiTitle>
                </Link>
                <EuiHorizontalRule margin="xs" />
                <EuiPanel hasBorder={false} hasShadow={false} style={PANEL_STYLE}>
                  <RemoteGrid />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem>
                <Link to="/Products/">
                  <EuiTitle size="s">
                    <h3>Products</h3>
                  </EuiTitle>
                </Link>
                <EuiHorizontalRule margin="xs" />
                <EuiPanel hasBorder={false} hasShadow={false} style={PANEL_STYLE}>
                  <ProductsGrid />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem>
                <Link to="/Software/">
                  <EuiTitle size="s">
                    <h3>Software</h3>
                  </EuiTitle>
                </Link>
                <EuiHorizontalRule margin="xs" />
                <EuiPanel hasBorder={false} hasShadow={false} style={PANEL_STYLE}>
                  <SoftwareGrid />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem>
                <Link to="/Advanced_User/">
                  <EuiTitle size="s">
                    <h3>For Developers</h3>
                  </EuiTitle>
                </Link>
                <EuiHorizontalRule margin="xs" />
                <EuiPanel hasBorder={false} hasShadow={false} style={PANEL_STYLE}>
                  <DeveloperGrid />
                </EuiPanel>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiPanel hasBorder={false} hasShadow={false} style={PANEL_STYLE}>
                  <EuiTitle size="s">
                    <h3>Popular Articles</h3>
                  </EuiTitle>
                  <EuiHorizontalRule margin="xs" />
                  <PopularGrid />
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
          <EuiTitle size="s">
              <h3>Latest Articles</h3>
          </EuiTitle>
          <EuiHorizontalRule margin="l" />
            <EuiFlexGroup gutterSize="s" direction="column">
              <LatestArticles />
            </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiHideFor>
    
    <EuiHideFor sizes={['m', 'l', 'xl']}>
        <EuiTitle size="s">
            <h3>Aktuelle Artikel</h3>
        </EuiTitle>
        <LatestArticles />
    </EuiHideFor>

  </React.Fragment>
  );
}

