import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import webUI720SearchIcon from '../../../../images/Search/QI_SearchThumb_WebUI_720p.webp'
import webUI1080SearchIcon from '../../../../images/Search/QI_SearchThumb_WebUI_1080p.webp'
import p2pcamliveiOSSearchIcon from '../../../../images/Search/P_SearchThumb_P2P_CamLive.webp'
import ipcamviewerAndroidSearchIcon from '../../../../images/Search/P_SearchThumb_IP_Cam_Viewer.webp'
import ispyWindowsSearchIcon from '../../../../images/Search/P_SearchThumb_iSpy_Connect.webp'
import yawWindowsSearchIcon from '../../../../images/Search/P_SearchThumb_YAW_Cam.webp'
import p2pClientWindowsSearchIcon from '../../../../images/Search/P_SearchThumb_P2P_Client.webp'
import cctvExtensionSearchIcon from '../../../../images/Search/P_SearchThumb_CCTV.webp'
import blueIris3WindowsSearchIcon from '../../../../images/Search/P_SearchThumb_Blue_Iris.webp'
import loxoneSearchIcon from '../../../../images/Search/AU_SearchThumb_Loxone.webp'
import homematicSearchIcon from '../../../../images/Search/AU_SearchThumb_Homematic.webp'
import homeySearchIcon from '../../../../images/Search/P_SearchThumb_Homey.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={webUI720SearchIcon}
                    alt="INSTAR IP Cameras Web User Interface"
                  />
                </div>
              }
              title="Network Menu // P2P"
              description="The P2P access allows you to access your camera´s live video stream via our iOS or Android app. Simply scan the P2P QR code with our app and confirm the settings - done! Please be aware, while the QR…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/720p_Series/Network/P2P/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={webUI1080SearchIcon}
                    alt="Network Menu // ONVIF"
                  />
                </div>
              }
              title="Network Menu // ONVIF"
              description="The surveillance industry has created an open standard protocol called ONVIF that allows cameras to communicate with each other and with network recording devices. You can activate or deactivate this…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Web_User_Interface/1080p_Series/Network/ONVIF/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={p2pcamliveiOSSearchIcon}
                    alt="iOS Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="P2P iOS Software for your INSTAR IP Camera"
              description="P2P Cam Live To access your IP camera with the iOS App P2P Cam Live we recommend that you first download the newest version from the app store. Use the search words P2PCam Live to find our app insi…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/iOS/P2P_Cam_Live/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={ipcamviewerAndroidSearchIcon}
                    alt="Android Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Android Software for your INSTAR IP Camera"
              description="IP Cam Viewer To access your IP camera with the Android App IP Cam Viewer we recommend that you first download the newest version from the app market. Use the search word IP Cam Viewer to find our ap…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Android/IP_Cam_Viewer/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={ispyWindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Windows Software for your INSTAR IP Camera"
              description="iSpy Connect iSpy uses your cameras, web cams, IP cams and microphones to detect and record movement or sound. Captured media is compressed to flash video or mp4 and streamed securely over the web an…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/iSpy/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={yawWindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Windows Software for your INSTAR IP Camera"
              description="Yet Another Webcam Viewer Yet Another Webcam Software? Maybe. But Yawcam delivers a punch in a small package. Yawcam is a webcam software for windows written in java. The main ideas for Yawcam are to…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/YAW_Cam/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={p2pClientWindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Windows Software for your INSTAR IP Camera"
              description="P2P Client The Point-2-Point protocol integrated in our HD camera line (not available for VGA models) allows you to connect to your camera from the internet without the need for a port forwarding rul…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/P2P_Client/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={cctvExtensionSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Windows Software for your INSTAR IP Camera"
              description="CCTV Browser Plugin This Google Chrome / Opera extension allows you to define up to 16 IP cameras or webcam urls. Every time the toolbar button is clicked it will show an updated version of the image…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/CCTV_Chrome_Plugin/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIris3WindowsSearchIcon}
                    alt="Windows Software for your INSTAR IP Camera"
                  />
                </div>
              }
              title="Windows Software for your INSTAR IP Camera"
              description="Blue Iris Use up to 64 cameras, capture JPEG snapshots or movies in standard MP4, AVI, advanced DVR, or Windows Media file formats. Keep an eye on your home, place of business, cars, and valuables; w…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Windows/Blue_Iris/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={loxoneSearchIcon}
                    alt="Loxone for INSTAR Cameras"
                  />
                </div>
              }
              title="Loxone"
              description="Homeautomation Miniserver Installation of the Miniserver Smart Home App and WebUI Control INSTAR IP camera via the Loxone Miniserver Example 1: Triggering an alarm Example 2: Go to a saved position D…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Other_Platforms/Loxone_Miniserver/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={homematicSearchIcon}
                    alt="Homematic CCU3 Hub"
                  />
                </div>
              }
              title="Homematic CCU3 Hub"
              description="Time-scheduled Camera Operations Installing the CUx Daemon Creating a Virtual Button Creating the Program Using the Homematic CCU3 as Alarmserver for our Camera Setting up a System Variable Configuri…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Other_Platforms/Homematic_IP/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={homeySearchIcon}
                    alt="Homey Smarthome Gateway"
                  />
                </div>
              }
              title="Homey Smarthome Gateway"
              description="Adding your Cameras Snapshot Control your Camera manually Notifying Homey when the Camera Alarm is triggered (Alarm Server) Using Zigbee/Z-Wave Sensors to control your Camera Time scheduled Camera co…"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link to="/Software/Other_Platforms/Athom_Homey/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
