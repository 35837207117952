import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function MotionGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        Configure your camera to trigger an alarm when motion is detected. Record videos, notify yourself by email or push messages and connect your camera to your smarthome environment.  
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Motion_Detection/Setup/"><EuiIcon type="grabHorizontal" /> Motion Detection Setup  </Link>
          <Link to="/Motion_Detection/Alarm_Notification/"><EuiIcon type="grabHorizontal" /> Alarm Notification  </Link>
          <Link to="/Motion_Detection/SD_Card_Access/"><EuiIcon type="grabHorizontal" /> SD Card Access  </Link>
          <Link to="/Motion_Detection/Alarm_FTP_Upload/"><EuiIcon type="grabHorizontal" /> Alarm FTP Upload  </Link>
          <Link to="/Motion_Detection/Router_as_a_FTP_Server/"><EuiIcon type="grabHorizontal" /> Router als FTP Server  </Link>
          <Link to="/Motion_Detection/FTP_Server_Setup/"><EuiIcon type="grabHorizontal" /> FTP Server Setup  </Link>
          <Link to="/Motion_Detection/Alarm_Server/"><EuiIcon type="grabHorizontal" /> Alarm Server  </Link>
          <Link to="/Motion_Detection/INSTAR_Cloud/"><EuiIcon type="grabHorizontal" /> INSTAR Cloud <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
