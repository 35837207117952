import React from 'react'
import { Link } from 'gatsby'

import {
  EuiText,
  EuiSpacer,
  EuiIcon
} from '@elastic/eui'

export default function SoftwareGrid() {

  return (
    <React.Fragment>
      <EuiText size="s">
        INSTAR software solutions and third-party software for your INSTAR camera.
      </EuiText>
      <EuiSpacer size="m" />
      <EuiText size="s" color="subdued">
        <p>
          <Link to="/Software/Windows/"><EuiIcon type="grabHorizontal" /> Software for Windows  </Link>
          <Link to="/Software/macOS/"><EuiIcon type="grabHorizontal" /> Software for macOS  </Link>
          <Link to="/Software/Linux/"><EuiIcon type="grabHorizontal" /> Software for Linux  </Link>
          <Link to="/Software/Android/"><EuiIcon type="grabHorizontal" /> Software for Android  </Link>
          <Link to="/Software/iOS/"><EuiIcon type="grabHorizontal" /> Software for iOS  </Link>
          <Link to="/Software/Home_Automation/"><EuiIcon type="grabHorizontal" /> Software for Home Automation  </Link>
          <Link to="/Software/Other_Platforms/"><EuiIcon type="grabHorizontal" /> Software for Other Platforms <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
