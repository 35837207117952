import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiButton,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'


import openHabSearchIcon from '../../../../images/Search/AU_SearchThumb_OpenHAB.webp'
import blueIrisSearchIcon from '../../../../images/Search/P_SearchThumb_Blue_Iris.webp'
import nodeRedSearchIcon from '../../../../images/Search/AU_SearchThumb_Node-RED.webp'
import faqSearchIcon from '../../../../images/Search/FAQ_SearchThumb.webp'
import lupusSearchIcon from '../../../../images/Search/P_SearchThumb_Lupus_XT2.webp'

export default function LatestArticle() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB and INSTAR IP Cameras"
                  />
                </div>
              }
              title="OpenHAB 3 Camera Widget"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/OpenHAB_3_Camera_Widget/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris v5 and INSTAR IP Cameras"
                  />
                </div>
              }
              title="BlueIris v5 Alarmserver"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Frequently_Asked_Question/BlueIris_v5_http_alarmserver/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB and INSTAR IP Cameras"
                  />
                </div>
              }
              title="OpenHAB 3 in Docker"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/OpenHAB_3_in_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB and INSTAR IP Cameras"
                  />
                </div>
              }
              title="OpenHAB 3 Camera"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/OpenHAB_3_Camera_Binding/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={openHabSearchIcon}
                    alt="OpenHAB 3 MQTT Binding"
                  />
                </div>
              }
              title="OpenHAB 3 MQTT"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/OpenHAB_3_MQTT_Binding/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris v5 Configure Preset Positions"
                  />
                </div>
              }
              title="BlueIris v5 Presets"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Frequently_Asked_Question/BlueIris_v5_preset_positions/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={nodeRedSearchIcon}
                    alt="Run a INSTAR Node-RED Dashboard in Docker"
                  />
                </div>
              }
              title="Node-RED Dashboard"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/Node-RED_in_Docker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={nodeRedSearchIcon}
                    alt="Node-RED Event Timeline for your MQTT Camera"
                  />
                </div>
              }
              title="Node-RED Timeline"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Advanced_User/Node-RED_Alarm_Event_Timeline/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris MQTT with Home Assistant"
                  />
                </div>
              }
              title="BlueIris with Home Assistant"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/Blue_Iris_v5/Home_Assistant/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris MQTT with Node-RED"
                  />
                </div>
              }
              title="BlueIris with Node-RED"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/Blue_Iris_v5/Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris MQTT with ioBroker"
                  />
                </div>
              }
              title="BlueIris with ioBroker"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/Blue_Iris_v5/ioBroker/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={blueIrisSearchIcon}
                    alt="BlueIris and INSTAR MQTT"
                  />
                </div>
              }
              title="BlueIris MQTT"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Windows/Blue_Iris_v5/INSTAR_MQTT/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={faqSearchIcon}
                    alt="Create a camera overview page in ioBroker Vis via Node-RED"
                  />
                </div>
              }
              title="ioBroker Vis via Node-RED"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Frequently_Asked_Question/ioBroker_INSTAR_Camera_Vis_with_Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={faqSearchIcon}
                    alt="Homebridge in Node-RED for Apple Homekit"
                  />
                </div>
              }
              title="Homebridge in Node-RED"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Frequently_Asked_Question/Homebridge_in_Node-RED/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <div>
                  <img
                    src={lupusSearchIcon}
                    alt="Lupus XT2 Plus"
                  />
                </div>
              }
              title="Lupus XT2 Plus"
              footer={
                <EuiFlexGroup justifyContent="flexEnd">
                  <Link  to="/Software/Other_Platforms/Lupus_XT2_Plus/">
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" fill>
                        Read
                      </EuiButton>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                  </Link>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}
