import React from 'react'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiText,
  EuiIcon
} from '@elastic/eui'

export default function PopularGrid() {

  return (
    <React.Fragment>
    <EuiSpacer size="m" />
    <EuiText size="s" color="subdued">
      <p>
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/"><EuiIcon type="grabHorizontal" /> All MQTT Topics  </Link>
        <Link to="/Advanced_User/INSTAR_MQTT_Broker/MQTT_vs_HTTP_API/"><EuiIcon type="grabHorizontal" /> MQTT vs CGI Commands  </Link>
        <Link to="/1080p_Series_CGI_List/"><EuiIcon type="grabHorizontal" /> 1080p CGI Commands  </Link>
        <Link to="/720p_Series_CGI_List/"><EuiIcon type="grabHorizontal" /> 720p CGI Commands  </Link>
        <Link to="/Web_User_Interface/1080p_Series/"><EuiIcon type="grabHorizontal" /> 1080p WebUI  </Link>
        <Link to="/Web_User_Interface/720p_Series/"><EuiIcon type="grabHorizontal" /> 720p WebUI  </Link>
        <Link to="/Software/Linux/MotionEye/"><EuiIcon type="grabHorizontal" /> MotionEye  </Link>
        <Link to="/Quick_Installation/Alternative_IP_Scanner/"><EuiIcon type="grabHorizontal" /> IP Camera Tool  </Link>
        <Link to="/Advanced_User/OpenHAB_IP_Camera_Binding/"><EuiIcon type="grabHorizontal" /> OpenHAB INSTAR Camera Binding  </Link>
        <Link to="/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"><EuiIcon type="grabHorizontal" /> Alarmserver Queries  </Link>
        <Link to="/Motion_Detection/Router_as_a_FTP_Server/WD_MyCloud_as_FTP_Server/"><EuiIcon type="grabHorizontal" /> WD MyCloud as FTP Server  </Link>
        <Link to="/Advanced_User/Node-RED_and_MQTT/Projects/Live_Video/"><EuiIcon type="grabHorizontal" /> Node-RED Live Video  </Link>
        <Link to="/Software/Windows/P2P_Client/"><EuiIcon type="grabHorizontal" /> P2P Client  </Link>
        <Link to="/Advanced_User/OpenHAB_IP_Camera_Binding/"><EuiIcon type="grabHorizontal" /> OpenHAB IP Camera Binding  </Link>
        <Link to="/Software/Linux/Shinobi_Open_Source_CCTV/"><EuiIcon type="grabHorizontal" /> Shinobi Open Source CCTV  </Link>
        <Link to="/Advanced_User/ZoneMinder_inside_a_Docker_Container/"><EuiIcon type="grabHorizontal" /> ZoneMinder and Docker  </Link>
        <Link to="/Advanced_User/IOBroker_on_Raspberry_Pi/motionEye/"><EuiIcon type="grabHorizontal" /> ioBroker and motionEye  </Link>
        <Link to="/Software/Linux/Node-RED/"><EuiIcon type="grabHorizontal" /> Node-RED  </Link>
        <Link to="/Software/Linux/Home_Assistant/"><EuiIcon type="grabHorizontal" /> Home Assistant  </Link>
        <Link to="/Advanced_User/Node-RED_and_IFTTT/"><EuiIcon type="grabHorizontal" /> Node-RED and IFTTT  </Link>
        <Link to="/Advanced_User/XiaoMi_Home_Zigbee2MQTT/"><EuiIcon type="grabHorizontal" /> XiaoMi Home Zigbee2MQTT <EuiIcon type="grabHorizontal" /></Link>
        </p>
      </EuiText>
    </React.Fragment>
  );
}
